<template>
  <v-card elevation="0" outlined>
    <v-card-text class="text-center">
      <div class="mb-3">
        <span class="text-h4">{{ total }}</span
        >&nbsp;
        <span class="text-caption">{{ item.name }}</span>
      </div>
      <v-sparkline
        :value="value"
        :gradient="gradient"
        :smooth="radius || false"
        :padding="padding"
        :line-width="width"
        :stroke-linecap="lineCap"
        :gradient-direction="gradientDirection"
        :fill="fill"
        :type="type"
        :auto-line-width="autoLineWidth"
        auto-draw
      ></v-sparkline>
      <div class="text-caption text-left">
        <br />x = last 12 months <br />y = total num of
        {{ item.name.toLowerCase() }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :to="item.route" text>MORE</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];

import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["item"],
  data: () => ({
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: "round",
    gradient: gradients[5],
    value: [],
    gradientDirection: "top",
    gradients,
    fill: false,
    type: "trend",
    autoLineWidth: false,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    allDates: [],
    count: [],
    total: 0,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;

      this.getDates();
    };
    //EOC
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.api.url = `${this.$api.servers.admin}/v1/count/${this.item.route.params.object}`;
      this.$api.fetch(this.api);
    },
    getDates() {
      var lastMonth = moment().subtract(1, "year").startOf("day");
      var i = 0;
      while (12 > i) {
        this.allDates.push(lastMonth.add(1, "month").format("MM/yyyy"));
        i++;
      }

      var temp_data = this.data;
      var data = [];
      var total = 0;
      this.allDates.forEach(function (date) {
        var count = 0;

        temp_data.forEach(function (item) {
          if (moment(item.timestampCreated).format("MM/yyyy") == date) {
            count++;
          }
        });
        total = count + total;
        data.push(count);
      });
      this.total = total;
      this.value = data;
    },
  },
};
</script>
<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC -->
    <v-card class="my-3">
      <v-card-title>Growth</v-card-title>
      <v-card-subtitle>Does our solution has growing demands?</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="6" sm="3" :key="item.name" v-for="item in menu">
            <ChartGrowth :item="item"></ChartGrowth>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="my-3">
      <v-card-title>Education</v-card-title>
      <v-card-subtitle
        >How do we know if a student improved after using our
        solution?</v-card-subtitle
      >
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Monthly Answered Questions</v-card-title>
              <v-card-subtitle>How many question answered?</v-card-subtitle>
              <v-card-text>
                <br />x = day/month <br />y = count <br />z = 7D, 30D, 1Y
                <br />lines = All, SJKC, SK
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Question per Student</v-card-title>
              <v-card-subtitle>MAQ/MAS</v-card-subtitle>
              <v-card-text>
                <br />x = day/month <br />y = count <br />z = 7D, 30D, 1Y
                <br />lines = All, SJKC, SK
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Corrects per Questions</v-card-title>
              <v-card-subtitle>Do students answered properly?</v-card-subtitle>
              <v-card-text>
                <br />x = day/month <br />y = count <br />z = 7D, 30D, 1Y
                <br />lines = All, SJKC, SK
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Mastered Topics</v-card-title>
              <v-card-subtitle
                >Plot monthly active students on scatter chart</v-card-subtitle
              >
              <v-card-text>
                <br />x = number of days since activated (less than 1Y) <br />y
                = count of mastered topics (>= 1 time 100% score for the topic)
                <br />line = average count
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="my-3">
      <v-card-title>Students</v-card-title>
      <v-card-subtitle
        >How to keep students interested in our solution?</v-card-subtitle
      >
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Hostname</v-card-title>
              <v-card-subtitle>Q: Do we need these sites?</v-card-subtitle>
              <v-card-text>
                <br />x = day/month <br />y = count <br />z = 7D, 30D, 1Y
                <br />lines = hostname
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Monthly Active Students</v-card-title>
              <v-card-subtitle
                >Q: Are we retaining our students?</v-card-subtitle
              >
              <v-card-text>
                <br />x = day/month <br />y = count <br />z = 7D, 30D, 1Y
                <br />lines = All, SJKC, SK
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Conversion Rate</v-card-title>
              <v-card-subtitle
                >Q: Are we converting student into MAU?</v-card-subtitle
              >
              <v-card-text>
                <br />x = % <br />y = new (100%), activated, joined, active
                <br />z = All time, new within 30~60D
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Game Features</v-card-title>
              <v-card-subtitle
                >Q: What attracts these students?</v-card-subtitle
              >
              <v-card-text>
                <br />x = count <br />y = api + (relative changes %) <br />z =
                7D, 30D, 1Y
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Event Features</v-card-title>
              <v-card-subtitle
                >Q: What attracts these students?</v-card-subtitle
              >
              <v-card-text>
                <br />x = count <br />y = api + (relative changes %) <br />z =
                7D, 30D, 1Y
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card elevation="0" outlined>
              <v-card-title>Trend of Features</v-card-title>
              <v-card-subtitle
                >Q: Is the feature getting more attractive?</v-card-subtitle
              >
              <v-card-text>
                <br />x = day/month <br />y = count <br />z = 7D, 30D, 1Y
                <br />z2 = features
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- EOC -->
    <!-- BOC:[grid] -->
    <!-- <v-row>
    <template v-for="item in menu">
      <v-col :key="item.name" class="col-12 col-sm-6 col-lg-3">
        <v-card :to="item.route">
          <v-card-text>
            <v-icon class="mr-2">{{item.icon}}</v-icon>
            <span>{{item.name}}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row> -->
    <!-- EOC -->
  </v-container>
</template>

<script>
import ChartGrowth from "@/components/Admin/Dashboard/ChartGrowth";
// import menu from '@/datasets/menu'
import { mapState } from "vuex";
export default {
  components: {
    ChartGrowth,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[grid]
    // menu:menu.Admin,
    menu: [],
    //EOC
  }),
  created() {
    this.menu = [
      {
        name: "Schools",
        icon: "mdi-bank",
        route: { name: "PageAdminDataBrowse", params: { object: "school" } },
      },
      {
        name: "Classrooms",
        icon: "mdi-google-classroom",
        route: { name: "PageAdminDataBrowse", params: { object: "classroom" } },
      },
      {
      name: 'Students',
      icon: 'mdi-account-group',
      route: { name: 'PageAdminDataBrowse', params: { object: 'student' } },
    },
     {
      name: 'Events',
      icon: 'mdi-calendar-star',
      route: { name: 'PageAdminDataBrowse', params: { object: 'event' } },
    },
    ];
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Dashboard",
      to: { name: "PageAdminDashboard" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>